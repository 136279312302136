<template>
  <div class="batchBox">
    <el-row class="accountCon">
      <el-col class="leftCon" :span="4">
        <div class="label1">
          <i class="el-icon-collection-tag" /> 优惠券信息
          <img src="@/assets/images/triangle.png" alt />
        </div>
      </el-col>
      <el-col class="rightCon" :span="20">
        <el-form
          ref="detail"
          label-position="right"
          label-width="140px"
          :rules="rules"
          :model="detail"
        >
          <el-form-item label="优惠券类型:" prop="type">
            <el-select class="w300" v-model="detail.type" placeholder="请选择优惠券类型">
              <el-option
                v-for="item in options.type"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="优惠券名称:" prop="name">
            <el-input v-model="detail.name" class="w300" maxlength="150" show-word-limit />
          </el-form-item>
          <el-form-item v-if="detail.type != 4 " label="使用门槛:" prop="use_threshold">
                满
                <el-input v-model="detail.use_threshold" class="w100" size="mini" /> 元可用
                <span class="phoneTip ml10">（0元为无限制）</span>
            
          </el-form-item>
           

          <el-form-item v-if="detail.type != 1" label="添加商品:">
                <!-- 部分商品 -->
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="mini"
                  @click="handleProduct('add')"
                >新增</el-button>
          </el-form-item>
          <el-form-item v-if="detail.type != 1 ">
            <el-table
              :data="goods"
              size="small"
              :cell-style="$style.cellStyle"
              :header-cell-style="$style.rowClass"
            >
              <el-table-column label="商品名称">
                <template slot-scope="scope">
                  <el-select
                    v-model="goods[scope.$index]"
                    size="mini"
                    value-key="id"
                    placeholder="请选择商品"
                    filterable
                    @change="(val) => selectChange(val, scope.$index)"
                  >
                    <el-option
                      v-for="(item, index) in product"
                      :key="index"
                      :label="item.name"
                      :value="item"
                      :disabled="
                            goods.findIndex((val) => val.id === item.id) > -1
                          "
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="商品编号" prop="goods_sn" />

              <el-table-column label="操作" width="120">
                <template slot-scope="{ row }">
                  <el-button size="mini" type="danger" @click="clickDel(row)">移除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item
            v-if="detail.type == 1 "
            label="面额:"
            prop="denomination"
          >
            <el-input v-model="detail.denomination" class="w300" type="span">
              <span slot="append">元</span>
            </el-input>
            <div class="phoneTip">面值只能是数值，0.01-10000，限2位小数</div>
          </el-form-item>
          
          

          <el-form-item label="有效期:" :prop="detail.valid == 1?'end_time':'fixed_days'">
             <el-date-picker
                  v-model="validData"
                  type="daterange"
                  range-separator="至"
                  :start-placeholder="this.detail.start_time?this.detail.start_time:'开始日期'"
                  :end-placeholder="this.detail.end_time?this.detail.end_time:'结束日期'"
                  value-format="yyyy-MM-dd"
                  @change="changeValid"
                ></el-date-picker>
          </el-form-item>
          
          <el-form-item label="使用类型:">
            <el-radio-group v-model="detail.use_type">
              <el-radio :label="1">商品可用</el-radio>
              <el-radio :label="2">服务可用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="每人限领:" prop="limit_number">
            <span >1 张</span>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" class="w300" v-model="detail.desc" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-button
            type="primary"
            size="mini"
            class="mainBtn"
            @click="submitForm('detail')"
          >完成，提交优惠券</el-button>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { goodsList } from "@/api/goods";
import { createCard, getCardDetail, updateCard } from "@/api/card";
class Detail {
  type = 1;
  name = "";
  use_threshold = 0;
  denomination = "";
  limit_number = 1;
  sequence = "";
  valid = 1;
  use_type = 1;
  goods_type = 1;
  start_time = "";
  end_time = "";
  desc = "";
  goodsList = [];
}
class Rules {
  name = [{ required: true, message: "请填写优惠券名称", trigger: "blur" }];
  use_threshold = [
    {
      pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
      message: "最多保留两位小数",
      trigger: "change"
    },
    { required: true, message: "请填写门槛限额", trigger: "blur" }
  ];
  denomination = [
    {
      pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
      message: "最多保留两位小数",
      trigger: "change"
    },
    { required: true, message: "请填写优惠券面额", trigger: "blur" }
  ];
  end_time = [{ required: true, message: "请填写有效期时间", trigger: "blur" }];
 
}
class Options {
  constructor() {
    this.type = [
      { id: 1, name: "满减券" },
      { id: 3, name: "满赠券" },
      { id: 4, name: "兑换券" }
    ];
  }
}
class Goods {
  id = "";
  name = "";
  goods_sn = "";
}
class Page {
  total = 0;
  page = 1;
  limit = 1000000;
}
export default {
  name: "AddCard",
  components: {},

  data() {
    return {
      detail: new Detail(),
      rules: new Rules(),
      goods: [new Goods()], // 已选列表
      options: new Options(), // 选项
      page: new Page(), // 分页
      product: [], // 商品列表
      validData: [] // 有效期时间
    };
  },
  mounted() {
    this.getGoodsList();
    this.$route.query.id ? this.getDetail() : "";
  },
  methods: {
    // 优惠券详情
    getDetail() {
      getCardDetail({ id: this.$route.query.id }).then(res => {
        this.detail = res;
        if (this.detail.type != 1) {
          this.goods = this.detail.goodsList.map(val => {
            const { id, name, goods_sn } = val;
            const goodsSelected = this.product.find(item => item.id == id);
            return val;
          });
        }
        
        if(this.detail.start_time){
            this.detail.valid = 1
        }else{
          this.detail.valid = 0
        }
        this.getDetailGoods();
      });
    },
    // 获取商品
    getGoodsList() {
      goodsList({  ...this.page }).then(res => {
        this.product = res.list;
      });
    },
    
    //改变有效期时间
    changeValid(e) {
      this.detail.start_time = e[0];
      this.detail.end_time = e[1];
    },
    // 商品操作
    handleProduct(type) {
      switch (type) {
        case "add":
          this.goods.push(new Goods());
          break;
      }
    },

    // 删除单商品
    clickDel(row) {
      this.goods = this.goods.filter(item => item.id !== row.id);
      this.getDetailGoods();
    },
    // 商品修改
    selectChange(val, index) {
      // 选中商品时，默认规格为第一项
      const { goods_sn } = val;
      this.goods[index].goods_sn = goods_sn;
      this.getDetailGoods();

    },
    // 同步选中商品
    getDetailGoods() {
      let one = {};
      let list = [];
      list = this.goods.map(i => {
        return i.id;
      });
        let obj = {};
        for(let key in list){
            obj[key] = list[key];
        }
        this.detail.goodsList = Object.keys(obj).map(val => ({
            id: obj[val]
        }))
    },

    // 确定新增商品
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (
            this.detail.goodsList.length == 0 && this.detail.type != 1
          ) {
            this.$message({
              message: "请选择部分商品添加哦~",
              type: "warning"
            });
          } else {
            this.hint("add", "新建此优惠券");
          }
        } else {
          return false;
        }
      });
    },
    // 提示
    hint(type, text) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == "add") {
            this.confirm();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 确定新增/编辑
    confirm() {
      if (this.$route.query.id) {
        updateCard({ ...this.detail, id: this.$route.query.id }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.$router.push("./cardList");
        });
      } else {
        createCard({ ...this.detail }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.$router.push("./cardList");
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.accountCon {
  background: #fff;
  position: relative;
  .leftCon {
    background: #fcfcfc;
    border-right: 1px solid #eee;
    position: absolute;
    height: 100%;
    > div {
      line-height: 48px;
      color: #fff;
      background: #9ea7b4;
      width: 100%;
      padding-left: 80px;
      font-size: 14px;
      box-sizing: border-box;
      img {
        position: absolute;
        right: -30px;
        width: 40px;
        height: 48px;
      }
    }
    .label1 {
      position: absolute;
      top: 40px;
    }
    .label2 {
      position: absolute;
      top: 270px;
    }
  }
  .rightCon {
    padding: 40px 0 0 500px;
    .btn {
      margin: 20px 0 50px 80px;
    }
    .mainBtn {
      margin: 30px 0 0 80px;
    }
    .phoneTip {
      font-size: 12px;
      color: #999;
    }
  }
}
</style>
