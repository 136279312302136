import axios from '@/utils/axios'

// 优惠券列表
export const getCardList = data =>
  axios.post('/admin/coupon/index', data)
    .then(res => res.data)

// 优惠券导出
export const cardexport = data =>
  axios.post('/admin/coupon/export', data)
    .then(res => res.data)

// 优惠券详情
export const getCardDetail = data =>
  axios.post('/admin/coupon/detail', data)
    .then(res => res.data)

// 添加优惠券
export const createCard = data =>
  axios.post('/admin/coupon/create', data)
    .then(res => res)
// 更新优惠券
export const updateCard = data =>
  axios.post('/admin/coupon/update', data)
    .then(res => res)

// 删除优惠券
export const deleteCard = data =>
  axios.post('/admin/coupon/delete', data)
    .then(res => res)

// 优惠券发放
export const provide = data =>
  axios.post('/admin/coupon/provide', data)
    .then(res => res)

// 优惠券发放详情
export const provideCoupon = data =>
  axios.post('/admin/coupon/provideCoupon', data)
    .then(res => res)

// 优惠券发放详情
export const getWriteOff = data =>
  axios.post('/crm/customer/getWriteOff', data)
    .then(res => res)

// 优惠券发放详情
export const oneProvide = data =>
  axios.post('/admin/coupon/oneProvide', data)
    .then(res => res)